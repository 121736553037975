import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from 'Layout';
import LoginPage from 'pages/login/Login';
import { routerData } from 'routerData';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <div>Not Found!</div>,
    children: routerData.map((router) => {
      if (!router.children) {
        return {
          path: router.path,
          element: router.element,
        };
      } else {
        return {
          index: false,
          path: router.path,
          children: router.children.map((router) => {
            return {
              path: router.path,
              element: router.element,
            };
          }),
        };
      }
    }),
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
