import React, { useEffect, useState } from 'react';
import { useLocation, Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import img01 from 'assets/layout/img-01.png';
import { ReactComponent as Depth } from 'assets/common/depth.svg';

interface DataList {
  [key: string]: string;
}

const depth1stData: DataList = {
  'user-info': '회원정보',
  'cs-list': 'CS 리스트',
  'facility-state': '시설 사용현황',
  robot: '로봇',
  emergency: '비상상황',
  community: '커뮤니티',
  'facility-info': '시설정보',
};

const depth2stData: DataList = {
  'meeting-room': '회의실',
  amenities: '편의시설',
  'common-space': '공용업무공간',
  device: '기기관리',
  'drive-mode': '운행모드',
  'management-time': '평균 관리 시간',
  forum: '자유게시판',
  userDetailInfo: '개인 상세 정보',
  chat: '채팅상담',
  videoCall: '화상통화 상담',
};

function Header() {
  const { pathname } = useLocation();
  const [depth1st, setDepth1st] = useState<string>('');
  const [depth2nd, setDepth2nd] = useState<string>('');
  const [depth3rd, setDepth3rd] = useState<string>('');
  const [URLSearchParams] = useSearchParams();
  const searchParams = URLSearchParams.get('detail') || '';

  useEffect(() => {
    const getPathList = (path: string) =>
      path.split('/').filter((path) => path !== '');

    const pathList = getPathList(pathname);

    for (const path in pathList) {
      const idx = parseInt(path);
      if (idx === 0) {
        setDepth1st(depth1stData[pathList[idx]]);
        setDepth2nd('');
        setDepth3rd('');

        if (pathList.length === 1 && depth1st === '시설 사용현황') {
          setDepth2nd('입주 사무실 리스트');
        }
        if (pathList.length === 1 && depth1st === '로봇') {
          setDepth2nd('공간정보');
        }
        if (pathList.length === 1 && depth1st === '커뮤니티') {
          setDepth2nd('공지사항');
        }
        continue;
      }

      if (idx === 1) {
        setDepth2nd(depth2stData[pathList[idx]]);
        continue;
      }
    }

    if (searchParams === 'userDetailInfo') {
      setDepth2nd(depth2stData[searchParams]);
    }

    if (searchParams === 'chat') {
      setDepth2nd(depth2stData[searchParams]);
    }
    if (searchParams === 'videoCall') {
      setDepth2nd(depth2stData[searchParams]);
    }

    if (searchParams === 'office') {
      setDepth3rd('와키컴퍼니');
    }

    if (searchParams === 'seminarRoom') {
      setDepth3rd('대형 세미나룸');
    }

    if (searchParams === 'phoneBooth') {
      setDepth3rd('시설 정보');
    }

    if (searchParams === 'mapEdit') {
      setDepth3rd('맵 수정');
    }

    if (searchParams === '029439FsxJ') {
      setDepth3rd('로봇 01(029439FsxJ)');
    }

    if (searchParams === 'modeEdit') {
      setDepth3rd('모드수정');
    }
    if (searchParams === 'noticeEdit' || searchParams === 'forumEdit') {
      setDepth3rd('게시글 상세');
    }
  }, [pathname, depth1st, depth2nd, depth3rd, searchParams]);

  return (
    <Container>
      <Gnb>
        <div>
          <Link to="/login" />
          <img src={img01} alt="" />
        </div>
      </Gnb>
      {depth1st && (
        <BreadCrumbs>
          <div className="content">
            <span>{depth1st}</span>
            {depth2nd && <Depth />}
            <span>{depth2nd}</span>
            {depth3rd && <Depth />}
            <span>{depth3rd}</span>
          </div>
        </BreadCrumbs>
      )}
    </Container>
  );
}

export default Header;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Gnb = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 84px;
  background: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #ced4da;
  z-index: 100;

  div {
    position: relative;
    width: 1200px;

    a {
      position: absolute;
      right: 0;
      width: 66px;
      height: 27px;
      background: transparent;
    }
  }

  img {
    display: block;
    width: 348px;
    margin-left: auto;
  }
`;

const BreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 84px;
  background: #fff;
  border-bottom: 1px solid #adb5bd;
  z-index: -1;

  .content {
    display: flex;
    align-items: center;
    width: 1200px;
    height: 50px;
  }

  .content span {
    color: #212529;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }
`;
