import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import img01 from 'assets/facility/img-01.png';
import img02 from 'assets/facility/img-02.png';

function OfficeList() {
  const [URLSearchParams] = useSearchParams();
  const detailPage = URLSearchParams.get('detail') || '';
  const navigate = useNavigate();

  const content = () =>
    detailPage ? (
      <img className="detail" src={img02} alt="" />
    ) : (
      <>
        <div className="btn01" onClick={() => navigate('?detail=office')}></div>
        <img className="list" src={img01} alt="" />
      </>
    );

  return <Container>{content()}</Container>;
}

export default OfficeList;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  overflow: hidden auto;

  .btn01 {
    position: absolute;
    top: 300px;
    width: 1200px;
    height: 72px;
    /* background: green; */
    opacity: 0.4;
    cursor: pointer;
  }

  img {
    display: block;
    width: 1200px;
    height: fit-content;
    margin-block: 40px 70px;
    user-select: none;
  }
`;
