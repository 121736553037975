const PATH = {
  home: '/',

  userInfo: '/user-info',

  CSList: '/cs-list',

  facilityState: '/facility-state',
  meetingRoom: '/facility-state/meeting-room',
  amenities: '/facility-state/amenities',
  commonSpace: '/facility-state/common-space',

  robot: '/robot',
  device: '/robot/device',
  deviceDetail: '/robot/device/:id',
  driveMode: '/robot/drive-mode',
  managementTime: '/robot/management-time',

  emergency: '/emergency',

  community: '/community',
  forum: '/community/forum',

  facilityInfo: '/facility-info',
};

export default PATH;
