import PATH from 'Path';
import HomePage from 'pages/home/Home';
import UserInfo from 'pages/userInfo/UserInfo';
import CSList from 'pages/csList/CSList';
import OfficeList from 'pages/facility/OfficeList';
import MeetingRoom from 'pages/facility/MeetingRoom';
import Amenities from 'pages/facility/Amenities';
import CommonSpace from 'pages/facility/CommonSpace';
import SpaceInfo from 'pages/robot/SpaceInfo';
import DriveMode from 'pages/robot/DriveMode';
import ManagementTime from 'pages/robot/ManagementTime';
import Emergency from 'pages/emergency/Emergency';
import Notice from 'pages/community/Notice';
import Forum from 'pages/community/Forum';
import FacilityInfo from 'pages/facilityInfo/FacilityInfo';
import DeviceDetailPage from 'pages/robot/DeviceDetailPage';
import DevicePage from './pages/robot/DevicePage';

export const routerData = [
  {
    path: PATH.home,
    label: '대시보드',
    element: <HomePage />,
  },
  {
    path: PATH.userInfo,
    label: '회원정보',
    element: <UserInfo />,
  },
  {
    path: PATH.CSList,
    label: 'CS 리스트',
    element: <CSList />,
  },
  {
    path: PATH.facilityState,
    label: '시설사용현황',
    children: [
      {
        path: PATH.facilityState,
        label: '입주 사무실 리스트',
        element: <OfficeList />,
      },
      {
        path: PATH.meetingRoom,
        label: '회의실',
        element: <MeetingRoom />,
      },
      {
        path: PATH.amenities,
        label: '편의시설',
        element: <Amenities />,
      },
      {
        path: PATH.commonSpace,
        label: '공용업무공간',
        element: <CommonSpace />,
      },
    ],
  },
  {
    path: PATH.robot,
    label: '로봇',
    children: [
      {
        path: PATH.robot,
        label: '공간정보',
        element: <SpaceInfo />,
      },
      {
        path: PATH.device,
        label: '기기관리',
        element: <DevicePage />,
      },
      {
        path: PATH.deviceDetail,
        label: '기기관리-상세',
        element: <DeviceDetailPage />,
      },
      {
        path: PATH.driveMode,
        label: '운행모드',
        element: <DriveMode />,
      },
      {
        path: PATH.managementTime,
        label: '평균 관리 시간',
        element: <ManagementTime />,
      },
    ],
  },
  {
    path: PATH.emergency,
    label: '비상상황',
    element: <Emergency />,
  },
  {
    path: PATH.community,
    label: '커뮤니티',
    children: [
      {
        path: PATH.community,
        label: '공지사항',
        element: <Notice />,
      },
      {
        path: PATH.forum,
        label: '자유게시판',
        element: <Forum />,
      },
    ],
  },
  {
    path: PATH.facilityInfo,
    label: '시설정보',
    element: <FacilityInfo />,
  },
];
