import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Img04 from 'assets/robot/img-04.png';
import Img05 from 'assets/robot/img-05.png';

function DriveMode() {
  const [URLSearchParams] = useSearchParams();
  const detailPage = URLSearchParams.get('detail') || '';
  const navigate = useNavigate();

  const content = () =>
    detailPage ? (
      <img className="detail" src={Img05} alt="" />
    ) : (
      <>
        <div
          className="btn01"
          onClick={() => navigate('?detail=modeEdit')}
        ></div>
        <img src={Img04} alt="" />
      </>
    );

  return (
    <Container>
      <div className="content">{content()}</div>
    </Container>
  );
}

export default DriveMode;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  overflow: hidden auto;

  .btn01 {
    position: absolute;
    top: 72px;
    left: 476px;
    width: 80px;
    height: 31px;
    /* background: green; */
    opacity: 0.4;
    cursor: pointer;
  }

  .content {
    position: relative;
    width: 1200px;
    height: fit-content;
  }

  img {
    display: block;
    width: 100%;
    height: fit-content;
    margin-block: 40px 70px;
    user-select: none;
  }
`;
