import React from 'react';
import styled from 'styled-components';
import Img01 from 'assets/facilityInfo/img-01.png';

function FacilityInfo() {
  return (
    <Container>
      <div className="content">
        <img src={Img01} alt="" />
      </div>
    </Container>
  );
}

export default FacilityInfo;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  overflow: hidden auto;

  .content {
    position: relative;
    width: 1200px;
    height: fit-content;
  }

  img {
    display: block;
    width: 100%;
    height: fit-content;
    margin-block: 40px 70px;
    user-select: none;
  }
`;
