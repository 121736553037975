import { useMemo, useEffect } from 'react';
import { useSearchParams, useNavigate, type To } from 'react-router-dom';
import styled from 'styled-components';
import { useSbCalls } from 'lib/sendbird-calls';
import CallView from 'components/views/CallView';
import img01 from 'assets/csList/img-01.png';
import img02 from 'assets/csList/img-02.jpg';

interface StyledProps {
  bg: boolean;
}

function CSList() {
  const [URLSearchParams] = useSearchParams();
  const detailPage = URLSearchParams.get('detail') || '';
  const navigate = useNavigate();
  const sbCalls = useSbCalls();
  const { calls } = useSbCalls();

  const onCall = useMemo(() => {
    return calls.find((call) => call.isOngoing);
  }, [calls]);

  useEffect(() => {
    if (onCall?.callState === 'ended') {
      sbCalls.clearCalls();
      navigate(-1 as To, {
        replace: true,
      });
    }
  }, [onCall?.callState]);

  useEffect(() => {
    return () => {
      sbCalls.clearCalls();
    };
  }, []);

  const goToChat = () => {
    navigate('?detail=chat');
  };

  const renderContent = () => {
    if (detailPage === 'chat') {
      return (
        <>
          <div className="btn05" onClick={() => navigate(-1)}></div>
          <img className="chat" src={img02} alt="" />
        </>
      );
    }

    if (detailPage === 'videoCall') {
      return (
        <>
          <div className="btn05" onClick={() => navigate(-1)}></div>
          <div className="video-call">
            {onCall && <CallView call={onCall} />}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="btn01"></div>
        <div className="btn02" onClick={goToChat}></div>
        <div className="btn03" onClick={goToChat}></div>
        <div className="btn04" onClick={goToChat}></div>
        <img className="list" src={img01} alt="" />
      </>
    );
  };

  return (
    <Container bg={detailPage === 'chat'}>
      <div className="content">{renderContent()}</div>
    </Container>
  );
}

export default CSList;

const Container = styled.div<StyledProps>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  background: ${({ bg }) => bg && '#f2f2f2'};

  .content {
    position: relative;
    width: 1200px;
    height: auto;
  }

  .chat {
    height: fit-content;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  }

  .video-call {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #555;
  }

  .btn05 {
    position: absolute;
    top: 20px;
    right: 33px;
    width: 121px;
    height: 32px;
    /* background: green; */
    opacity: 0.5;
    cursor: pointer;
  }

  .btn01,
  .btn02,
  .btn03,
  .btn04 {
    position: absolute;
    right: 13px;
    width: 124px;
    height: 24px;
    /* background: green; */
    opacity: 0.5;
    cursor: pointer;
  }

  .btn01 {
    top: 260px;
  }
  .btn02 {
    top: 298px;
  }
  .btn03 {
    top: 336px;
  }
  .btn04 {
    top: 374px;
  }

  .list {
    margin-block: 40px 70px;
  }

  img {
    display: block;
    width: 100%;
    user-select: none;
  }
`;
