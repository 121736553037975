import styled from 'styled-components';
import img01 from 'assets/home/home-01.jpg';
import img02 from 'assets/home/home-02.jpg';
import img03 from 'assets/home/home-03.jpg';
import img04 from 'assets/home/home-04.jpg';
import img05 from 'assets/home/home-05.jpg';
import img06 from 'assets/home/home-06.jpg';

const contents = [img01, img02, img03, img04, img05, img06];

function HomePage() {
  return (
    <Container>
      <ContentSection>
        {contents.map((item, idx) => (
          <img
            key={Math.random().toString()}
            className={`img-0${idx + 1}`}
            src={item}
            alt=""
          />
        ))}
      </ContentSection>
    </Container>
  );
}

export default HomePage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  overflow: hidden scroll;
`;

const ContentSection = styled.div`
  position: relative;
  width: 1200px;
  height: 1049px;

  [class^='img-0'] {
    position: absolute;
    display: block;
    width: 584px;
    height: fit-content;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    overflow: hidden;
  }

  & img:nth-child(odd) {
    left: 0;
  }

  & img:nth-child(even) {
    right: 0;
  }

  .img-01,
  .img-02 {
    top: 48px;
  }

  .img-03 {
    top: 420px;
  }

  .img-04 {
    top: 349px;
  }

  .img-05,
  .img-06 {
    top: 711px;
  }
`;
