import ReactDOM from 'react-dom';
// import 'normalize.css';
import App from './App';
import { SbCallsProvider } from './lib/sendbird-calls';
import Globalstyle from 'styles/globals';

ReactDOM.render(
  <>
    <Globalstyle />
    <SbCallsProvider appId="">
      <App />
    </SbCallsProvider>
  </>,
  document.getElementById('root') as HTMLDivElement,
);
