import { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { getCallOption } from 'utils/variables';
import { useSbCalls } from 'lib/sendbird-calls';
import CallView from 'components/views/CallView';
import axios from 'axios';
import BG_IMG from 'assets/robot/img-07.jpg';

function DeviceDetailPage() {
  const sbCalls = useSbCalls();
  const { calls } = useSbCalls();

  const onCall = useMemo(() => {
    return calls.find((call) => call.isOngoing);
  }, [calls]);

  useEffect(() => {
    if (onCall?.callState === 'ended') {
      sbCalls.clearCalls();
    }
  }, [onCall?.callState]);

  useEffect(() => {
    return () => {
      sbCalls.clearCalls();
    };
  }, []);

  const dial = (isVideoCall: boolean) => {
    sbCalls.dial({
      userId: 'nuvi-user',
      isVideoCall,
      callOption: getCallOption(),
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const message = new FormData(e.currentTarget).get('message');

    if (!message) {
      console.log('Empty');
      return;
    }

    axios
      .post(
        'https://b6w76ag1qi.execute-api.ap-northeast-2.amazonaws.com/api/v1/test/notice',
        {
          msg: message,
        },
      )
      .then(function (response) {
        // response
        console.log(response);
      })
      .catch(function (error) {
        // 오류발생시 실행
        console.log(error);
      })
      .then(function () {
        // 항상 실행
      });

    console.log(message);
  };

  return (
    <Container>
      <div className="content">
        {onCall && <CallView call={onCall} />}
        <img
          onClick={() => dial(true)}
          className="bg-img"
          src={BG_IMG}
          alt="배경 이미지"
        />
        <TextForm onSubmit={onSubmit}>
          <TextArea name="message" />
          <FormAction>음성 송출</FormAction>
        </TextForm>
      </div>
    </Container>
  );
}

export default DeviceDetailPage;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100dvb - 168px);
  overflow: hidden auto;

  .content {
    position: relative;
    width: 1200px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    .bg-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
`;

const TextForm = styled.form`
  position: absolute;
  left: 34px;
  bottom: 34px;
  width: calc(100% - 68px);
  height: 98px;
  z-index: 100;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
  border: 1px solid #e9ecef;
  background-color: #f8f8f8;
  padding: 17px 104px 17px 17px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;

  color: #000;
  font-size: 15px;
  line-height: normal;

  &:focus {
    outline: none;
  }
`;

const FormAction = styled.button`
  position: absolute;
  right: 19px;
  bottom: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 32px;
  padding: 6px;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #1c77f9;
  border: none;
  color: #f8f9fa;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  user-select: none;
`;
