import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/common/close.svg';
import { ReactComponent as Bell } from 'assets/common/bell.svg';
import img01 from 'assets/CSModal/img-01.jpg';

function CSModal() {
  const [showModal, setModal] = useState(false);
  return (
    <Container>
      <Dot onClick={() => setModal(!showModal)}>
        {showModal ? <Close /> : <Bell />}
      </Dot>
      {showModal && (
        <Modal>
          <div className="action" />
          <img src={img01} alt="" />
        </Modal>
      )}
    </Container>
  );
}

export default CSModal;

const Container = styled.div`
  position: fixed;
  right: 45px;
  bottom: 70px;
  z-index: 999;
`;

const Dot = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #ff7a00;
  box-shadow: 0px 11px 8px 0px rgba(0, 0, 0, 0.11);
  cursor: pointer;

  &::before {
    content: '2';
    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background-color: #1c77f9;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }
`;

const Modal = styled.div`
  position: absolute;
  bottom: 94px;
  right: 0;
  width: 316px;
  height: fit-content;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 18px 34px 0px rgba(0, 0, 0, 0.13);
  border: 1px solid #ced4da;

  .action {
    position: absolute;
    top: 208px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 260px;
    height: 34px;
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    user-select: none;
    pointer-events: none;
  }
`;
