import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import img01 from 'assets/userInfo/img-01.png';
import img02 from 'assets/userInfo/img-02.png';

function UserInfo() {
  const [URLSearchParams] = useSearchParams();
  const detailPage = URLSearchParams.get('detail') || '';
  const navigate = useNavigate();

  console.log(detailPage);

  const content = () =>
    detailPage === 'userDetailInfo' ? (
      <img className="user-detial-info" src={img02} alt="" />
    ) : (
      <>
        <div
          className="action"
          onClick={() => navigate('?detail=userDetailInfo')}
        />
        <img className="user-info" src={img01} alt="" />
      </>
    );

  return <Container>{content()}</Container>;
}

export default UserInfo;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden auto;

  img {
    width: 1200px;
    height: fit-content;
    pointer-events: none;
    user-select: none;
  }

  .action {
    position: absolute;
    width: 1200px;
    height: 38px;
    z-index: 100;
    margin-top: 254px;
    cursor: pointer;
    /* background: green; */
    opacity: 0.4;
  }

  .user-info {
    margin-top: 40px;
    margin-bottom: 70px;
  }

  .user-detial-info {
    margin-top: 75px;
    margin-bottom: 70px;
  }
`;
