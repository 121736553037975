import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { routerData } from 'routerData';
import { ReactComponent as Logo } from 'assets/common/logo.svg';

function SideBar() {
  return (
    <Container>
      <LogoWrapper>
        <Logo width={103} height={28} fill="#ffffff" />
      </LogoWrapper>
      <LargeList className="large-list">
        {routerData.map((item) => (
          <li className="large-item" key={item.label}>
            <NavLink
              to={item.path}
              style={({ isActive }) => {
                return {
                  background: isActive && !item.children ? '#0865EB' : '',
                };
              }}
            >
              {item.label}
            </NavLink>
            {item.children && (
              <ul className="small-list">
                {item.children.map((item) => (
                  <li className="small-item" key={item.label}>
                    <NavLink to={item.path} end>
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </LargeList>
    </Container>
  );
}

export default SideBar;

const Container = styled.div`
  width: 247px;
  height: 100%;
  background: #1c77f9;
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-block: 38px 118px;
`;

const LargeList = styled.ul`
  & a {
    display: flex;
    width: 100%;
    padding-inline: 33px 34px;
    color: #fff;
    line-height: normal;
    text-decoration-line: none;
  }

  .large-item > a {
    padding-block: 15px 17px;
    font-size: 16px;
    font-weight: 700;
    transition: background 300ms ease-in-out;
  }

  .large-item > a.active ~ .small-list {
    background: #0865eb;
    max-height: 300px;
  }

  .small-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 700ms ease-in-out;
  }

  .small-list li:first-child {
    padding-top: 22px;
  }

  .small-list li:last-child {
    padding-bottom: 22px;
  }

  .small-item a {
    font-size: 14px;
    font-weight: 400;
  }

  .small-item a.active {
    font-weight: 800;
  }
`;
