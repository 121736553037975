import { useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toast.override.css';
import { AuthOption, SoundType } from 'sendbird-calls';
import storage from './lib/storage';
import { ErrorMessage } from './components/atoms/Toast';
import { useSbCalls } from './lib/sendbird-calls';
import { useLocation } from 'react-use';
import { useTextInput } from './components/atoms/Input';
import styled from 'styled-components';
import SideBar from './components/layout/SideBar';
import Header from 'components/layout/Header';
import CSModal from 'components/layout/CSModal';

function Layout() {
  const navigate = useNavigate();
  const sbCalls = useSbCalls();
  const query = new URLSearchParams(useLocation().search);
  let authArgs: any = {};
  const authQuery = query.get('q');
  if (authQuery) {
    try {
      authArgs = JSON.parse(atob(authQuery));
    } catch (e) {}
  }

  const stored = storage.getItem('sbCalls');

  const APP_ID =
    authArgs.app_id || stored?.appId || process.env.REACT_APP_APP_ID || '';
  const USER_ID =
    authArgs.user_id || stored?.userId || process.env.REACT_APP_USER_ID || '';
  const ACCESS_TOKEN =
    authArgs.access_token ||
    stored?.accessToken ||
    process.env.REACT_APP_ACCESS_TOKEN ||
    '';
  const IS_ACCESS_TOKEN_NEEDED =
    process.env.REACT_APP_IS_ACCESS_TOKEN_NEEDED === 'true';
  const ROOM_ID = authArgs.room_id || '';

  const [appId, appIdInput] = useTextInput({
    id: 'appIdInput',
    initValue: APP_ID,
  });
  const [userId, userIdInput] = useTextInput({
    id: 'userIdInput',
    initValue: USER_ID,
  });
  const [accessToken, accessTokenInput] = useTextInput({
    id: 'accessTokenInput',
    initValue: ACCESS_TOKEN,
  });

  useEffect(() => {
    login();

    return () => {
      sbCalls.clearCalls();
    };
  }, []);

  const login = () => {
    const option: AuthOption = { userId };
    if (IS_ACCESS_TOKEN_NEEDED || authArgs.access_token)
      option.accessToken = accessToken;
    sbCalls.init(appId);
    sbCalls.addDirectCallSound(SoundType.DIALING, '/sounds/Dialing.mp3');
    sbCalls.addDirectCallSound(SoundType.RINGING, '/sounds/Ringing.mp3');
    sbCalls.addDirectCallSound(
      SoundType.RECONNECTING,
      '/sounds/Reconnecting.mp3',
    );
    sbCalls.addDirectCallSound(
      SoundType.RECONNECTED,
      '/sounds/Reconnected.mp3',
    );
    console.log('appId : ' + appId);
    console.log('userId : ' + userId);
    return sbCalls
      .auth(option)
      .then((user) => {
        console.log(user);
        console.log(ROOM_ID);
        storage.setItem('sbCalls', { appId, userId });
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          <ErrorMessage message={`Check entered information and try again.`} />,
          { autoClose: 2000 },
        );
      });
  };

  const onCall = useMemo(() => {
    return sbCalls.calls.find((call) => call.isOngoing);
  }, [sbCalls.calls]);

  useEffect(() => {
    if (onCall?.callState === 'ringing') {
      window.confirm('화상통화 상담을 수락하시겠습니까?')
        ? navigate('/cs-list?detail=videoCall')
        : sbCalls.clearCalls();
    }
  }, [onCall?.callState]);

  return (
    <Container>
      <SideBar />
      <Wrapper>
        <Header />
        <Outlet />
      </Wrapper>
      <CSModal />
    </Container>
  );
}

export default Layout;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
