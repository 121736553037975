import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const Globalstyle = createGlobalStyle`
${reset};

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html, body, #root {
    min-width: 1447px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'NanumSquare', sans-serif;
}

iframe {
    display: none;
}

input {
    font-family: 'NanumSquare', sans-serif;
}

button {
    font-family: 'NanumSquare', sans-serif;
}
`;

export default Globalstyle;
