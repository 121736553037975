import React from 'react';
import styled from 'styled-components';
import img07 from 'assets/facility/img-07.png';

function CommonSpace() {
  return (
    <Container>
      <img src={img07} alt="" />
    </Container>
  );
}

export default CommonSpace;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  overflow: hidden auto;

  img {
    display: block;
    width: 1200px;
    height: fit-content;
    margin-block: 40px 70px;
    user-select: none;
  }
`;
