import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Background from 'assets/login/img-01.jpg';
import { ReactComponent as Logo } from 'assets/common/logo.svg';
import Share from 'assets/login/img-02.jpg';

interface StyledProps {
  marginBtm: string;
}

function LoginPage() {
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const loginPayload = {
      id: formData.get('id') as string,
      pw: formData.get('pw') as string,
    };

    if (loginPayload.id && loginPayload.pw) {
      console.log(loginPayload.id, loginPayload.pw);

      navigate('/');
    }
  };

  return (
    <Container>
      <Modal onSubmit={handleSubmit}>
        <LogoWrapper>
          <img className="share-logo" src={Share} alt="logo" />
          <Logo width={200} height={55} fill="#1C77F9" />
        </LogoWrapper>
        <Input placeholder="업체명" name="id" marginBtm="10px" />
        <Input
          placeholder="비밀번호"
          name="pw"
          type="password"
          marginBtm="24px"
        />
        <Button type="submit">로그인</Button>
        <SignUpWrapper>
          <p className="description">아직 회원이 아니신가요?</p>
          <p className="sign-up">회원가입</p>
        </SignUpWrapper>
      </Modal>
    </Container>
  );
}

export default LoginPage;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: ${`url(${Background})`};
  background-size: cover;
  background-repeat: no-repeat;
`;

const Modal = styled.form`
  width: 530px;
  height: 503px;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  padding: 68px 135px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.06);
`;

const LogoWrapper = styled.div`
  margin-inline: 30px;
  margin-bottom: 59px;
  .share-logo {
    display: block;
    width: 64px;
    height: 27px;
    border-radius: 50px;
    margin-left: auto;
    margin-bottom: 14px;
  }
`;

const Input = styled.input<StyledProps>`
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  overflow: hidden;
  background: #f8f8f8;
  outline: initial;
  border: initial;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: ${({ marginBtm }) => marginBtm};

  ::placeholder {
    color: #adb5bd;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 13px 10px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1c77f9;
  border: initial;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  user-select: none;
  transition: opacity 250ms ease-in-out;
  margin-bottom: 35px;

  :active {
    opacity: 0.8;
  }
`;

const SignUpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: #495057;
  font-size: 14px;
  line-height: normal;

  .description {
    font-weight: 400;
  }

  .sign-up {
    font-weight: 700;
    text-decoration-line: underline;
    text-underline-offset: 0.15em;
    cursor: pointer;
    user-select: none;
  }
`;
