import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import img06 from 'assets/facility/img-06.png';
import img05 from 'assets/facility/img-05.png';

function Amenities() {
  const [URLSearchParams] = useSearchParams();
  const detailPage = URLSearchParams.get('detail') || '';
  const navigate = useNavigate();

  const content = () =>
    detailPage ? (
      <img className="detail" src={img06} alt="" />
    ) : (
      <>
        <div
          className="btn01"
          onClick={() => navigate('?detail=phoneBooth')}
        ></div>
        <img className="list" src={img05} alt="" />
      </>
    );

  return (
    <Container>
      <div className="content">{content()}</div>
    </Container>
  );
}

export default Amenities;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  overflow: hidden auto;

  .btn01 {
    position: absolute;
    top: 260px;
    left: 410px;
    width: 380px;
    height: 185px;
    /* background: green; */
    opacity: 0.4;
    cursor: pointer;
  }

  .content {
    position: relative;
    width: 1200px;
    height: fit-content;
  }

  img {
    display: block;
    width: 100%;
    height: fit-content;
    margin-block: 40px 70px;
    user-select: none;
  }
`;
